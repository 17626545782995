// === Mediaqueries OFFER ===

@include media-breakpoint-to-sm {
  // HP
  .client-brand {
    img {
    max-height: 177px;
    }
  }
}

@include media-breakpoint-up-md {
  // CLIENT BRANDS
  .hover-wrap {
    margin: 0;
    .hover-brand {
      margin-bottom: .7rem;
    }
    .hover-descript {
      height: 123px;
      overflow: hidden;
      padding-bottom: 0;
    }
  }
  .teaser-sm {
    height: 200px !important;
  }
  .section-brands {
    .download {
      .visual,
      .descript {
        float: left;
        bottom: -24px;
      }
      .descript {
        width: 84%;
      }
      a {
        display: inline-block;
        margin: 0;
      }
    }
  }
}

@include media-breakpoint-up-md {
  // client brands
  .clients-wrap {
    padding-top: .8rem;
    padding-bottom: .5rem;
  }
  .client-brand {
    padding: 12px;
  }
  .new-client {
    .novelty {
      img {
        height: 27px;
      }
    }
  }
  .hover-wrap {
    padding: .4rem .7rem .5rem .7rem;
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
    .hover-brand {
      margin-bottom: 0.1rem;
      height: 21px;
    }
    p {
      line-height: 1.2;
      font-size: .9rem;
      margin-top: 0;
    }
  }
  .hover-in {
    .raquo-l {
      a {
        font-size: .95rem;
      }
    }
  }
}

@include media-breakpoint-up-lg {
  .hover-wrap {
    width: 132%;
    height: 132%;
    top: -16%;
    left: -16%;
  }
}

@include media-breakpoint-up-xl {
  // client brands
  .clients-wrap {
    div[class^="col-"] {
      @include flex(0 0 14.286%);
      max-width: 14.286%;
    }
  }
  .hover-wrap {
    width: 130%;
    height: 130%;
    top: -15%;
    left: -15%;
  }
}
