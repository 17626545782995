// ----- Bootstrap breakpoints -----

@mixin media-breakpoint-up-sm {
	@media screen and (min-width: $breakpoint-min-sm) {
	 @content;
	}
}

@mixin media-breakpoint-up-md {
	@media screen and (min-width: $breakpoint-min-md) {
	 @content;
	}
}

@mixin media-breakpoint-up-lg {
	@media screen and (min-width: $breakpoint-min-lg) {
	 @content;
	}
}

@mixin media-breakpoint-up-xl {
	@media screen and (min-width: $breakpoint-min-xl) {
	 @content;
	}
}

@mixin media-breakpoint-to-xs {
	@media screen and (max-width: $breakpoint-max-xs) {
	 @content;
	}
}

@mixin media-breakpoint-to-md {
	@media screen and (max-width: $breakpoint-max-md) {
	 @content;
	}
}

@mixin media-breakpoint-to-sm {
	@media screen and (max-width: $breakpoint-max-sm) {
	 @content;
	}
}

// ----- Extra breakpoints -----

@mixin media-breakpoint-360-xs {
	@media screen and (min-width: $breakpoint-min-360) and (max-width: $breakpoint-max-xs) {
	 @content;
	}
}

@mixin media-breakpoint-up-480-xs {
	@media screen and (min-width: $breakpoint-min-480) {
	 @content;
	}
}

@mixin media-breakpoint-only-md {
	@media screen and (min-width: $breakpoint-min-md) and (max-width: $breakpoint-max-md) {
	 @content;
	}
}


// mixins for browser prefixes

@mixin transition($properties) {
	-webkit-transition: $properties;
	-moz-transition: $properties;
	-o-transition: $properties;
	-ms-transition: $properties;
	transition: $properties;
}

@mixin transform($properties) {
	-webkit-transform: $properties;
	-moz-transform: $properties;
	-ms-transform: $properties;
	-o-transform: $properties;
	transform: $properties;
}

@mixin animation($properties) {
    -moz-animation: $properties;
    -o-animation: spin-horizontal $properties;
    -webkit-animation: spin-horizontal $properties;
    -ms-animation: $properties;
    animation: $properties;
}

@mixin border-radius($properties) {
	border-radius: $properties;
  -moz-border-radius: $properties;
  -webkit-border-radius: $properties;
}

@mixin background-size($properties) {
	-webkit-background-size: $properties;
    -moz-background-size:  $properties;
    -o-background-size:  $properties;
    background-size:  $properties;
}

@mixin box-shadow($properties) {
	-webkit-box-shadow: $properties;
	-moz-box-shadow: $properties;
	box-shadow: $properties;
}

@mixin box-shadow($properties) {
	-webkit-box-shadow: $properties;
	-moz-box-shadow: $properties;
	box-shadow: $properties;
}

@mixin display-flex() {
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin display-flex-important() {
	display: -webkit-box !important;
	display: -moz-box !important;
	display: -ms-flexbox !important;
	display: -webkit-flex !important;
	display: flex !important;
}

@mixin display-inline-flex() {
	display: -ms-inline-flexbox;
    display: -webkit-flex;
    display: inline-flex;
}

@mixin flex($properties) {
	-webkit-box-flex: $properties;
	-webkit-flex: $properties;
	-ms-flex: $properties;
	flex: $properties;
}

@mixin flex-wrap($properties) {
	-webkit-flex-wrap: $properties;
	-ms-flex-wrap: $properties;
	flex-wrap: $properties;
}

@mixin align-items($properties) {
	-webkit-align-items: $properties;
	align-items: $properties;
}

@mixin justify-content($properties) {
	-webkit-box-pack:  $properties;
	-webkit-justify-content: $properties;
	-ms-flex-pack: $properties;
	justify-content: $properties;
}

@mixin align-self($properties) {
	-webkit-align-self: $properties;
	-ms-flex-item-align: $properties;
	align-self: $properties;
}

@mixin flex-direction($properties) {
	-webkit-flex-direction: $properties;
	-ms-flex-direction: $properties;
	flex-direction: $properties;
}
