/* ----- ABOUT SUB-PAGE ----- */

.section-about {
  margin-top: .8rem;
  .section {
    margin-bottom: 1.5rem;
  }
  a.button {
    float: none;
    display: block;
    text-align: center;
    margin: 1.4rem 0 1.5rem 0;
    padding: 1rem .5rem;
  }
  .btn-wrap {
    width: 100%;
    display: block;
    position: relative;
  }
  .icon-img {
    img {
      display: block;
      margin: 0 auto;
      width: 60%;
    }
  }
  .about-img {
    width: 100%;
    margin: 1rem 15px;
     img {
      width: 100%;
      height: auto;
     }
  }
  .about-toggle {
    position: relative;
    top: -1.5rem;
  }
  .btn-style {
    margin-bottom: 1rem;
    position: relative;
    img {
      position: absolute;
      top: 50%;
      right: 15px;
      @include transform(translateY(-50%));
      width: 30px;
    }
  }
  .border-hide {
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
  }
  .distribution,
  .price,
  .logistics,
  .cat-online,
  .manage {
    .in-title {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }
  .distribution,
  .price,
  .logistics,
  .cat-online,
  .manage {
    .in-wrap {
      border-left: 0;
    }
    .visual {
      margin: .4rem auto .9rem auto;
      img {
        width: 100%;
        height: 100%;
        position: relative;
      }
    }
    .descript {
      width: 100%;
      p {
        margin-bottom: 0;
      }
      .raquo-l {
        margin-bottom: 0;
        margin-top: .5rem;
      }
    }
  }
  /* COOPERATION AND INVITATION */
  .coop-item {
    img {
      width: 100px;
      display: block;
      margin: 0 auto;
      padding: 1rem 0;
    }
    .coop-wrap {
      p {
        margin-bottom: .2rem;
      }
    }
  }
}
