/*
 Theme Name:   PBStheme
 Theme URI:    www.pbspolska.eu
 Author:       Ginger Coders
 Author URI:   www.gingercoders.pl
 Version:      1.0.0
*/

/* This stylesheet generated by Transfonter (http://transfonter.org) */
@font-face {
  font-family: 'Lato';
  src: url("./fonts/Lato-Light.eot");
  src: url("./fonts/Lato-Light.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-Light.woff") format("woff"), url("./fonts/Lato-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Lato Hairline';
  src: url("./fonts/Lato-HairlineItalic.eot");
  src: url("./fonts/Lato-HairlineItalic.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-HairlineItalic.woff") format("woff"), url("./fonts/Lato-HairlineItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("./fonts/Lato-Italic.eot");
  src: url("./fonts/Lato-Italic.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-Italic.woff") format("woff"), url("./fonts/Lato-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("./fonts/Lato-BlackItalic.eot");
  src: url("./fonts/Lato-BlackItalic.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-BlackItalic.woff") format("woff"), url("./fonts/Lato-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("./fonts/Lato-BoldItalic.eot");
  src: url("./fonts/Lato-BoldItalic.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-BoldItalic.woff") format("woff"), url("./fonts/Lato-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("./fonts/Lato-Regular.eot");
  src: url("./fonts/Lato-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-Regular.woff") format("woff"), url("./fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("./fonts/Lato-LightItalic.eot");
  src: url("./fonts/Lato-LightItalic.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-LightItalic.woff") format("woff"), url("./fonts/Lato-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Lato';
  src: url("./fonts/Lato-Bold.eot");
  src: url("./fonts/Lato-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-Bold.woff") format("woff"), url("./fonts/Lato-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Lato Hairline';
  src: url("./fonts/Lato-Hairline.eot");
  src: url("./fonts/Lato-Hairline.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-Hairline.woff") format("woff"), url("./fonts/Lato-Hairline.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Lato';
  src: url("./fonts/Lato-Black.eot");
  src: url("./fonts/Lato-Black.eot?#iefix") format("embedded-opentype"), url("./fonts/Lato-Black.woff") format("woff"), url("./fonts/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

// Layout project styles

html, body {
	font-family: 'Lato', sans-serif;
	color: $color-basic;
}
p, ul, ol {
	color: $color-basic;
}
ul, ol {
  padding-left: 15px;
}
p {
  font-weight: 300;
  font-size: 1.1rem;
  text-align: justify;
}
a {
  color: $color-red;
}
a, a.button {
	text-decoration: none;
}
a:active, a:hover, a:focus {
	color: $color-light !important;
	text-decoration: none !important;
  outline: none !important;
}
a.button,
.button a {
	border: 1px solid $color-red;
	cursor: pointer;
	color: $color-red;
    padding: .4rem .7rem;
    font-size: .9rem;
    text-transform: uppercase;
}
a.button:active, a.button:hover,
.button a:active, .button a:hover {
	color: $color-basic !important;
	border: 1px solid $color-basic !important;
}
.button {
  display: block;
}
button, input, optgroup, select, textarea {
	font-family: 'Lato', sans-serif;
}
table {
  width: 100%;
}
.p-footnote {
  font-size: 1rem;
}
// link with raquo
.raquo-l {
  display: block;
  text-align: right;
  margin-bottom: .5rem;
  font-size: 1.1rem;
  font-weight: 400;
}
.raquo-l.std {
  text-align: left;
}
.raquo-l a::after {
  content: '\00BB';
  padding-left: 4px;
  font-size: 1.1rem;
  font-weight: 400;
  color: $color-red;
}
.raquo-l a:active::after,
.raquo-l a:hover::after,
.raquo-l a:focus::after {
  color: $color-light;
}

// Owl carousel - custom styles
.owl-carousel {
  .owl-nav {
    position: absolute;
    right: 0;
    bottom: -35px;
    z-index: 1;
    .owl-prev,
    .owl-next {
      display: inline-block;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      opacity: 1;
    }
    .owl-prev {
      margin-right: 34px;
      background-image: url("./images/arrow-prev.png");
    }
    .owl-prev::after {
      content: "";
      height: 100%;
      width: 2px;
      position: absolute;
      background: #e1001a;
      right: 36px;
      top: -3px;
      z-index: 1;
    }
    .owl-next {
      background-image: url("./images/arrow-next.png");
    }
  }
}

.page-basic {
  margin-top: 2rem;
}
.clearboth {
  clear: both;
}
.hide {
  display: none !important;
}
.open {
  display: block !important;
}
.vis-hidden {
  visibility: hidden;
}
.open-flex {
  @include display-flex-important();
}
.pbshide {
  display: none;
}
.pbsopen {
  display: block;
}

// text styling
.p-capital {
  text-transform: uppercase;
}
.p-bold {
  font-weight: 700;
  color: $color-basic;
}
.p-red {
  color: $color-red;
}

// Plugin MimeTypes Icons
.mtli_attachment.mtli_pdf {
    background-image: url(./images/pdf-icon-22x28.jpg) !important;
}
.mtli_attachment.mtli_ppt {
  background-image: url(./images/ppt-icon-22x28.jpg) !important;
}
.mtli_attachment.mtli_pptx {
  background-image: url(./images/ppt-icon-22x28.jpg) !important;
}
.mtli_attachment.mtli_zip {
  background-image: url(./images/zip-icon-22x28.jpg) !important;
}
.mtli_attachment.mtli_doc {
  background-image: url(./images/doc-icon-22x28.jpg) !important;
}
.mtli_attachment.mtli_docx {
  background-image: url(./images/doc-icon-22x28.jpg) !important;
}
.mtli_attachment.mtli_xls {
  background-image: url(./images/xls-icon-22x28.jpg) !important;
}
.mtli_attachment.mtli_xlsx {
  background-image: url(./images/xls-icon-22x28.jpg) !important;
}
.mtli_attachment {
  min-height: 28px !important;
  padding-left: 28px !important;
  font-size: 1rem;
  position: relative;
  padding-top: 9px;
  margin-left: .2rem;
}
