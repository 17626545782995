/* ----- SINGLE TEMPLATE ----- */

.post-basic {
  margin-top: 2.5rem;
  p,
  .p-title,
  h2,
  h3,
  h4,
  ul,
  ol,
  table {
    color: $color-light;
    font-weight: 300;
  }
  h2 {
    font-size: 1.4rem;
    font-weight: 400;
  }
  h3 {
    font-size: 1.3rem;
    font-weight: 400;
  }
  h4 {
    font-size: 1.2rem;
    font-weight: 400;
  }
  a {
    color: $color-basic;
    font-weight: 400;
  }
  a:active, a:hover, a:focus {
    color: $color-red !important;
  }
  // link + raquo
  .raquo-l a::after {
    color: $color-basic;
  }
  .raquo-l a:active::after,
  .raquo-l a:hover::after,
  .raquo-l a:focus::after {
    color: $color-red;
  }
  // button: more articles
  a.button:active,
  a.button:hover,
  a.button:focus {
    color: $color-basic !important;
  }
  a.button:focus {
    border-color: $color-basic !important;
  }
  ul,
  ol {
    font-size: 1.1rem;
  }
  ul li,
  ol li {
    margin-bottom: .3rem;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .capital {
    text-transform: uppercase;
  }
  .title-single {
    margin-bottom: .5rem;
  }
  .in-title {
    border-bottom: 1px solid $color-super-light;
  }
  .p-title {
    font-size: 1.8rem;
  }
  .image-single {
    div {
      @include display-flex();
      @include justify-content(center);
      @include align-items(flex-start);
      margin-bottom: 1.2rem;
      margin: 0 auto !important;
      max-width: 40%;
      padding-bottom: 1rem;
    }
  }
  .post-cat-link {
    @include display-flex();
    a.button {
      float: none;
      margin: 2rem auto 1rem auto;
      color: $color-red;
      padding: .8rem 2rem;
      font-size: 1.1rem;
    }
  }
  .post-icons {
    margin-top: 2rem;
    img {
      width: 50px;
      margin-right: .5rem;
    }
  }
  .p-capital {
    text-transform: uppercase;
  }
  .p-bold {
    font-weight: 400;
    color: $color-basic;
  }
  .p-red {
    color: $color-red;
  }
  table td {
    border: 1px solid $color-light;
    padding: .5rem 1rem;
    font-size: 1rem;
  }
  //  editor style
  .embedleft {
    float: left;
    margin-right: 10px;
  }
}
