/* ----- SECTION CLIENT BRANDS/ OFFER -----  */

.client-brands {
  .in-title.slider {
    border-bottom: 0;
  }
}
.brands-intro {
  p {
    color: $color-light;
  }
}
div[class^="col-"].client-box {
  padding: 10px;
  background-color: #ffffff;
  position: relative;
}
.box {
  width: 100%;
  height: 100%;
}
.clients-wrap {
  margin: 0 30px 2rem 30px;
  width: 100%;
  div[class^="col-"] {
    border-top: 1px solid $color-super-light;
    border-left: 1px solid $color-super-light;
    position: relative;
  }
  div[class^="col-"]::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: $color-super-light;
    left: 0;
    bottom: -1px;
  }
  div[class^="col-"]::after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: $color-super-light;
    right: -1px;
    top: 0;
  }
}
.client-wrap {
  width: 100%;
  max-height: 100%;
  position: relative;
}
.client-brand {
  width: 100%;
  height: 100%;
  position: absolute;
  padding: 20px;
  background-color: #ffffff;
  top: 0;
  div {
    height: 100%;
    width: 100%;
    display: block;
    position: relative;
    img {
      max-height: 100%;
      position: relative;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
    }
  }
}
// NEW client box
.new-client {
  .novelty {
    position: absolute;
    width: auto;
    top: 0;
    right: 10%;
    z-index: 1;
    img {
      height: 30px;
      position: relative;
      display: block;
    }
  }
}
// New client exclamation
.novelty {
  display: none;
}
.new-client {
  .novelty {
    display: block;
  }
}
// HP HOVER (client brands)
.hover-wrap {
  position: absolute;
  width: 130%;
  height: 130%;
  top: -15%;
  left: -15%;
  background-color: #ffffff;
  padding: 1rem;
  z-index: 999;
  opacity: 0;
  @include transition(all .5s ease-in-out);
  border: 1px solid $color-bg-2;
  @include box-shadow(2px 2px 0px 0px #c3c3c3);
  margin: 10px 0;
  .hover-in {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    position: relative;
  }
  .hover-brand {
    float: left;
    width: 80%;
    margin-bottom: .3rem;
    position: relative;
    height: 30px;
    img {
      height: 100%;
      top: 0;
      position: absolute;
    }
  }
  .close {
    float: right;
    width: 16px;
    opacity: 1;
  }
  .close:hover {
    opacity: .75;
  }
  .hover-descript {
    height: auto;
    width: 100%;
    padding-bottom: 2rem;
  }
  p {
    font-size: 1rem;
    line-height: 1.3;
    color: $color-light;
    margin-bottom: 0;
    margin-top: .5rem;
  }
}
.hover-in {
  .raquo-l {
    position: absolute;
    bottom: -4px;
    right: 0;
    margin-bottom: 0;
  }
}
.hover-wrap.client-mobile {
  position: relative;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  opacity: 1;
  visibility: visible;
}
.client-box:hover .hover-wrap {
  visibility: visible;
  opacity: 1;
}

/* CLIENT BRANDS/ OFFER SUB-PAGE */
.section-brands {
  margin-top: 2rem;
  .download {
    .visual {
      width: 85px;
      margin: 1rem 1rem 1rem 0;
    }
    img {
      width: 100%;
    }
    .descript {
      position: relative;
    }
    p {
      margin-bottom: 0;
    }
    a {
      font-weight: 300;
      color: $color-light;
      display: block;
      margin-bottom: .5rem;
      font-size: 1.1rem;
    }
    a:focus,
    a:hover,
    a:active {
      color: $color-red !important;
    }
  }
  .fallback {
    filter: alpha(opacity=0);
    opacity: 0;
    height: 0;
    padding: 0;
  }
}
