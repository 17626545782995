/* ----- CATEGORY TEMPLATE ----- */

.categories-page {
  margin-top: 2rem;
  p, a, h1, h2, h3, h4 {
    color: $color-light;
  }
  a {
    font-size: 1.3rem;
    font-weight: 300;
    line-height: 1.3;
    display: block;
    border-bottom: 1px solid $color-super-light;
    padding-bottom: .2rem;
    margin-bottom: 1rem;
  }
  a:active, a:hover, a:focus {
    border-bottom: 1px solid $color-red;
    color: $color-red !important;
  }
}
