// === Mediaqueries CONTACT PAGE == */

@include media-breakpoint-to-md {
  //  tabs/accordion
  .section-contact {
    .tabs {
      display: none;
    }
    ul.tabs li {
      overflow: hidden;
      position: relative;
    }
    .tab_accordion_head {
      color: $color-red;
      text-transform: uppercase;
      border: 1px solid $color-red;
      font-size: 1.2rem;
      margin: 1rem 0;
      padding: 20px 15px;
      display: block;
      cursor: pointer;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .tab_accordion_head:hover,
    .tab_accordion_head:active,
    .tab_accordion_head:focus,
    .d_active {
      color: $color-light;
      border: 1px solid $color-light;
    }
    .d_active,
    .d_active:hover {
      border-bottom: 0;
      border-left: 0;
      border-right: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-left: 0;
    }
  }
}

@include media-breakpoint-up-sm {
  .section-contact {
    //  tabs/accordion
    .label {
      width: 70%;
    }
  }
}

@include media-breakpoint-up-md {
  .section-contact {
    .contact-img {
      overflow: hidden;
      width: 41%;
      img {
        width: auto;
        min-width: 100%;
        min-height: 100%;
      }
    }
    .g-map {
      overflow: hidden;
      width: 54%;
      margin-left: 0;
      margin-right: 0;
    }
    .contact-img ,
    .g-map {
      margin-top: 2rem;
      margin-bottom: 2rem;
      height: 273px;
    }
  }
  .c-member {
    margin-top: 1rem;
  }
}

@include media-breakpoint-up-lg {
  .section-contact {
    .g-map {
      width: 55%;
    }
    .contact-img ,
    .g-map {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .c-member {
      margin-top: 2rem;
    }
    //  tabs/accordion
    .tab_content {
      padding: 10px 0;
      display: none;
    }
    ul.tabs {
      li {
        padding: 0 13px;
      }
      li:first-of-type {
        padding-left: 0;
      }
      li:last-of-type {
        padding-right: 0;
      }
      li:hover {
        color: $color-light;
        font-weight: 400;
      }
      li.active {
        color: $color-basic;
        font-weight: 400;
      }
      li:hover::before,
      li:active::before,
      li:focus::before,
      li.active::before {
        content: "";
        position: absolute;
        bottom: -14px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid $color-super-light;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      li:hover::after,
      li:active::after,
      li:focus::after,
      li.active::after {
        content: "";
        position: absolute;
        bottom: -15px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid #ffffff;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 1;
      }
    }
  }
}

@include media-breakpoint-up-xl {
  .section-contact {
    //  tabs/accordion
    ul.tabs li {
      padding: 0 23px;
      font-size: 1.1rem;
    }
    .label:hover::before,
    .label:active::before,
    // .input:before:focus
    .input:focus + .label::before,
    .input:checked + .label::before {
      content: "";
      position: absolute;
      bottom: -15px;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #e1001a;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    .label:hover::after,
    .label:active::after,
    // .input:before:focus
    .input:focus + .label::after,
    .input:checked + .label::after {
      content: "";
      position: absolute;
      bottom: -14px;
      width: 0;
      height: 0;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      border-top: 15px solid #ffffff;
      left: 0;
      right: 0;
      margin: 0 auto;
      z-index: 1;
    }
    .panel {
      order: 99;
    }
  }
}
