// === Mediaqueries CATEGORY TEMPLATE == */
@include media-breakpoint-up-md {
	.categories-page {
	  a {
    	font-size: 1.4rem;
    	max-width: 70%;
    }
	}
}
@include media-breakpoint-up-lg {
	.categories-page {
	  a {
	    font-size: 1.5rem;
	    line-height: 1.5;
	    margin-bottom: 1.3rem;
	  }
	}
}
