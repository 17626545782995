/* ----- CONTACT PAGE ----- */

.section-contact {
  .pbs-contact {
    .in-wrap {
      padding-bottom: .5rem;
    }
  }
  .contact-img {
    margin-top: 2rem;
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
    img {
      width: 100%;
      height: auto;
    }
  }
  .g-map {
    margin-top: 1rem;
    margin-left: 15px;
    margin-right: 15px;
    width: 100%;
    margin-bottom: 1rem;
    iframe {
      width: 100%;
      max-width: 100%;
      pointer-events: none;
    }
  }
  .contact-img,
  .g-map {
    border: 1px solid $color-super-light;
  }
  // tabs/ accordion
  ul.tabs {
    margin: 0 15px;
    padding: 0;
    float: left;
    list-style: none;
    width: 100%;
  }
  .tabs::before {
    content: "";
    width: auto;
    height: 1px;
    top: 45px;
    position: absolute;
    border-bottom: 1px solid $color-super-light;
    left: 15px;
    right: 15px;
  }
  ul.tabs li {
    float: left;
    margin: 0;
    cursor: pointer;
    padding: 0px 21px;
    height: 31px;
    line-height: 31px;
    font-size: 1rem;
    color: $color-basic;
    position: relative;
  }
  ul.tabs li:hover {
    color: $color-red;
    font-weight: 700;
    border: 0;
  }
  ul.tabs li.active {
    color: $color-red;
    font-weight: 700;
    border: 0;
    display: block;
  }
  .tab_container {
    border-top: none;
    clear: both;
    float: left;
    width: 100%;
    padding: 0 15px;
    overflow: auto;
  }
  .tab_content {
    padding: 0;
    display: none;
  }
  .tab_accordion_head {
    display: none;
  }
}

// Team member - contact
.c-member {
  margin-top: 1rem;
  p {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.3;
  }
  p:not(.c-position) {
    margin-bottom: .1rem;
  }
  a {
    font-weight: 400;
    color: $color-red;
  }
  a:active, a:hover, a:focus {
    color: $color-basic !important;
  }
  .c-region {
    font-size: 1.1rem;
    font-weight: 300;
  }
  .c-name {
    margin-bottom: .2rem;
    font-weight: 700;
    font-size: 1rem;
  }
  .c-position {
    margin-bottom: .1rem;
    font-style: italic;
  }
}
.c-member.regions {
  img {
    margin-top: .6rem;
    margin-bottom: .6rem;
  }
}
