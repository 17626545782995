/* === Mediaqueries SINGLE TEMPLATE === */
@include media-breakpoint-up-md {
  .post-basic {
    .image-single {
      div {
        max-width: 80%;
        margin: 0;
      }
    }
    .in-title {
    	margin-bottom: 2rem;
    }
  }
}
