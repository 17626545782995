/* ----- NAVBAR ----- */

.navbar-main {
  width: 100%;
  padding-bottom: 0;
  padding-top: 0;
  padding-top: .9rem;
  background-color: #ffffff;
  .nav-wrap {
    @include display-flex();
    @include flex-direction(column);
    @include align-items(center);
    @include justify-content(space-between);
  }
  .nav-item {
    position: relative;
  }
  .nav-link {
    color: $color-light !important;
    font-size: 1.1rem;
    font-weight: 300;
    text-transform: uppercase;
  }
  .nav-link:focus,
  .nav-link:hover {
    color: $color-red !important;
  }
  .navbar-brand {
    z-index: 10;
    img {
      max-width: 65px;
    }
  }
  .navbar-toggler {
    border: 0;
    top: 15px;
    left: 0;
    padding-left: 15px;
    padding-right: 15px;
    @include display-inline-flex();
  }
  .navbar-toggler:focus {
    outline: 0;
  }
  .navbar-toggler-icon.open-btn {
    background-image: url("./images/icon-mobile.png");
    width: 32px;
    height: 26px;
  }
  .navbar-toggler-icon.close-btn {
    background-image: url("./images/close.jpg");
    width: 32px;
    height: 26px;
  }
  .hide-toggle-btn {
    display: none;
  }
  .navbar-nav {
    @include flex-direction(row);
    @include flex-wrap(wrap);
    .nav-item:not(.social-icon) {
      width: 100%;
    }
  }
  // main menu on mobile (main, outlined & social links)
  #navbarNav {
    @include align-self(flex-start);
    width: auto;
    max-width: 100%;
    z-index: 10;
    @include box-shadow(2px 2px 8px -5px #222);
    margin-top: 16px;
    .nav-item:not(.social-icon) {
      border-bottom: 1px solid $color-red;
      padding-left: 15px;
      z-index: 2;
    }
    .active>.nav-link,
    .nav-link.active,
    .nav-link.open,
    .open>.nav-link {
      color: $color-basic !important;
    }
  }
  // outlined links
  .outlined {
    .nav-link {
      color: $color-red !important;
      font-weight: 300;
    }
    .nav-link:hover {
      color: $color-basic !important;
    }
  }
  // social icons
  .social-icon {
    margin: .9rem 7px .8rem 15px;
    cursor: pointer;
    position: relative;
    z-index: 1;
    .nav-link {
      border: 1px solid #4d4d4d;
      height: 27px;
      width: 27px;
      border-radius: 100%;
      padding: 0;
      float: right;
      position: relative;
    }
    .fa {
      position: absolute;
      top: 50%;
      left: 50%;
      @include transform(translate(-50%, -50%));
      font-size: .8rem;
      color: $color-basic;
    }
  }
  .social-icon:focus .nav-link,
  .social-icon:hover .nav-link {
    border-color: $color-red;
  }
  .social-icon:focus .fa,
  .social-icon:hover .fa {
    color: $color-red !important;
  }
  // language switch
  .lang {
    @include flex-direction(row);
    position: absolute;
    top: 15px;
    right: 0;
    padding-right: 15px;
    padding-left: 15px;
    .lang-item:last-of-type {
      margin-left: 10px;
    }
    a {
      font-size: 1.3rem;
      text-transform: uppercase;
      color: $color-light;
      font-weight: 300;
    }
    a:active, a:hover, a:focus {
      color: $color-red !important;
      font-weight: 400;
    }
  }
  .lang::before {
    content: "";
    height: 58%;
    width: 1px;
    position: absolute;
    background: $color-basic;
    left: 0;
    top: 8px;
  }
  // class fired via jQuery
  .active-lang {
    color: $color-red !important;
    font-weight: 400 !important;
  }
}
// nav bottom line
.selector {
  height: 15px;
  @include box-shadow(0 4px 6px -6px #222);
  position: absolute;
  width: 100%;
  top: 71px;
}

/* ----- MAIN SLIDER ----- */

#main-carousel {
  margin: 2rem 0 1.5rem 0;
  width: 100%;
  .slider-caption {
    position: relative;
    text-align: left;
    left: 0;
    bottom: 0;
    color: $color-basic;
    margin: .7rem 0;
    width: 100%;
  }
  .caption {
    font-size: 1.4rem;
    margin-bottom: 0;
    color: $color-light;
  }
  .caption, a.button {
    font-weight: 300;
  }
  a.button {
    padding: .2rem .5rem;
    font-size: .9rem;
    font-weight: 300;
    display: inline-block;
    text-transform: uppercase;
    background-color: #ffffff;
    margin-top: .7rem;
  }
  .owl-nav {
    bottom: .6rem;
  }
}

/* ----- SECTIONS ----- */
.hp-sections {
  .wrap {
    @include display-flex();
    @include flex-direction(column);
    height: 100%;
  }
  .in-wrap {
    @include flex(1 0 auto);
  }
}
.section {
  margin-bottom: 1.5rem;
}
// section title
.in-title {
  margin-bottom: 1rem;
  border-bottom: 1px solid $color-super-light;
  padding-bottom: .5rem;
  .title-h2 {
    font-size: 1.6rem;
    color: $color-light;
    font-weight: 400;
    margin-bottom: 0;
    padding-right: 15px;
  }
  p {
    margin-bottom: 3px;
  }
}
/* HP SECTIONS */
// News section
.hp-sections {
  hr {
    width: 100%;
    margin: 0 15px .5rem 15px;
    border-top: 1px solid $color-super-light;
  }
}
.news {
  .in-title {
    margin-bottom: .5rem;
  }
  .excerpt-i {
    position: relative;
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 1rem 1.5rem;
  }
  .wp-post-image {
    position: relative;
    width: auto;
    height: auto;
    max-height: 100px;
    max-width: 40%;
    display: block;
    margin: 0 auto;
    display: block;
  }
  .excerpt-h {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.3;
    margin-bottom: .3rem;
    a {
      color: $color-light;
    }
    a:active, a:hover, a:focus {
      color: $color-red !important;
    }
  }
  .excerpt-t {
    p {
      text-align: justify;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
// Carousel with client logos
#carousel-clients {
  .owl-stage-outer {
    border: 1px solid $color-super-light !important;
  }
  .owl-item {
    img {
      width: auto;
      max-width: 100%;
    }
  }
  .owl-item.active {
    .client-brand {
    border-left: 1px solid $color-super-light;
    outline-width: thin;
    left: -2px;
    }
  }
  .owl-item.active:first-of-type {
    border-left: 0;
  }
  .owl-nav {
    bottom: -45px;
  }
  .client-brand {
    padding: 25px;
  }
  .novelty {
    img {
      width: auto;
      height: 24px;
    }
  }
}
/* ----- FOOTER -----  */
footer {
  .foot-newsletter {
    margin-top: 0;
    margin-bottom: 0;
  }
  // newsletter sign-up
  .newsletter-xl {
    padding: 0;
    margin-bottom: 0;
    height: 150px;
    width: 100%;
    background-image: url('./images/newsletter_bg.png');
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    .wrap {
      position: relative;
      width: 100%;
      height: auto;
      overflow: hidden;
      padding-left: 6rem;
      padding-right: 2rem;
      top: 50%;
      @include transform(translateY(-50%));
      // fallback in case of shrotcode
      div {
        background-color: transparent !important;
        background: transparent !important;
      }
      // fallback in case of shrotcode
      > div {
        position: absolute !important;
        width: 100%!important;
        right: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
      }
    }
    h3{
      float: left;
      margin: 0;
      font-weight: 400;
      font-size: 1rem;
      color: $color-basic;
      height: 40px;
      line-height: 2.2;
    }
    form {
      float: right !important;
      width: 45% !important;
      input[type=text] {
        width: 60% !important;
        height: 32px !important;
        border: 1px solid $color-red !important;
        position: relative !important;
        margin: 0 2% !important;
        text-align: center !important;
      }
      input[type=submit],
      button {
        position: relative !important;
        background-color: $color-red !important;
        background: $color-red !important;
        border: 1px solid  $color-red !important;
        color: #ffffff !important;
        height: 32px !important;
        font-size: .8rem !important;
        text-transform: uppercase !important;
        width: 96px !important;
        font-weight: 400 !important;
        top: -1px !important;
        border-radius: 0 !important;
        cursor: pointer !important;
      }
      // fallback in case of shrotcode
      p {
        display: inline-block !important;
        width: auto !important;
      }
      // fallback in case of shrotcode
      div {
        display: inline !important;
      }
      p, div {
        margin: 0 !important;
        padding: 0 !important;
      }
      input::-webkit-input-placeholder {
        font-size: .75rem !important;
        text-align: center !important;
      }
      input:-moz-placeholder {
        font-size: .75rem !important;
        text-align: center !important;
      }
      input::-moz-placeholder {
        font-size: .75rem !important;
        text-align: center !important;
      }
      input:-ms-input-placeholder {
        font-size: .75rem !important;
        text-align: center !important;
      }
    }
  }
  .rights {
    padding-bottom: .5rem;
    padding-top: 2.5rem;
    p {
      text-align: center;
      font-size: .9rem;
    }
  }
}

/* ----- Error 404 ----- */
.error-404 {
  .button {
    margin-top: 2rem;
    a {
      padding: .7rem 1rem;
    }
  }
}

/* ----- Cookie Notice AdFactory Plugin -----  */
#cookie-notice {
  z-index: 9999999;
}
.cookie-notice-container {
  background: $color-bg;
  border-top: 1px solid $color-super-light;
  padding-bottom: 14px;
}
#cn-notice-text {
  display: block;
  color: $color-basic;
  font-family: 'Lato', sans-serif;
}#cookie-notice {

}
#cookie-notice .button.wp-default {
  border-radius: 0;
  text-shadow: none;
  padding: 4px 10px;
  margin-top: 6px;
  border-radius: 0;
  text-transform: none;
  font-family: 'Lato', sans-serif;
}
