/* === Mediaqueries === */

@include media-breakpoint-to-xs {
  .nav-wrap {
    width: 100%;
  }
}

@include media-breakpoint-to-sm {
  // hide flipping catalogue on small screens
  .flip-catalogue {
    display: none;
  }
  //  General
  table {
    td {
      display: block;
      margin-top: -1px;
    }
  }
}

@include media-breakpoint-up-sm {
  // NAVBAR
  .navbar-main {
    #navbarNav {
      width: 70%;
      margin-top: 13px;
    }
    .social-icon  {
      margin-right: 10px;
    }
  }
}

@include media-breakpoint-up-md {
  // NAVBAR
  .navbar-main {
    .navbar-brand {
      img {
        max-width: 70px;
      }
    }
    .navbar-toggler {
      top: 19px;
    }
    #navbarNav {
      width: 60%;
    }
    // language switch
    .lang {
      top: 15px;
    }
  }
  // nav bottom line
  .selector {
    top: 76px;
  }
  // HP SLIDER
  #main-carousel{
    margin-bottom: 1rem;
    .slider-caption {
      margin-top: 0;
      margin-bottom: 0;
      width: 90%;
    }
    .caption {
      display: inline-block;
      margin-right: 10px;
      font-size: 1.2rem;
      margin-bottom: .7rem;
      margin-top: .6rem;
    }
    a.button {
      margin-right: 5px;
      margin-top: 0;
      position: relative;
      bottom: .1rem;
      font-size: .8rem;
      padding: .2rem .5rem;
    }
    .owl-nav {
      right: 0;
      bottom: .85rem;
      .owl-prev,
      .owl-next {
        width: 17px;
        height: 17px;
      }
      .owl-prev {
        margin-right: 30px;
      }
      .owl-prev::after {
        right: 31px;
      }
    }
  }
  // SECTIONS HP
  // section title
  .in-title {
    .title-h2 {
      font-size: 1.7rem;
    }
  }
  .news {
    .excerpt-i {
      margin: 0;
      width: auto;
      height: auto;
      padding: 1rem;
      @include flex(1);
    }
    .wp-post-image {
      max-height: 100px;
      max-width: 120px;
    }
    .excerpt-h {
      font-size: 1.4rem;
      line-height: 1.2;
      margin-top: .5rem;
    }
  }
  .news.thumb {
    @include display-flex();
    @include justify-content(center);
    @include align-items(center);
  }
  .in-wrap {
    padding-top: 15px;
  }
  footer {
    .foot-newsletter {
      margin-top: 1rem;
    }
  }
}
@include media-breakpoint-up-lg {
  // GENERAL
  // link with raquo
  .raquo-l,
  .raquo-l a::after {
    font-size: 1rem;
  }
  body {
    padding-top: 92px;
  }
  //  NAVBAR
  .navbar {
    padding-left: 0;
    padding-right: 0;
    // fix top
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 9999999;
  }
  .navbar-main {
    @include box-shadow(0 4px 6px -6px #222);
    padding-bottom: .5rem;
    padding-top: .5rem;
    .nav-wrap {
      @include flex-direction(row);
    }
    .navbar-brand {
      margin-right: .6rem;
      img {
        max-width: 75px;
      }
    }
    .navbar-toggler {
      display: none;
    }
    // main menu on desktops (main, outlined & social links)
    #navbarNav {
      width: 100%;
      @include align-self(flex-end);
      @include box-shadow(none);
      .navbar-nav {
        width: 100%;
      }
      .nav-item:not(.social-icon) {
        padding-right: .6rem;
        padding-left: .6rem;
        border: 0;
        width: auto;
      }
      // all links
      .nav-link {
        font-size: 1.13rem;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0;
      }
      // last social link
      li:last-of-type {
        margin-right: 84px;
      }
    }
    // outlined links
    .outlined {
      padding-right: 0 !important;
      padding-left: 1.2rem !important;
      margin-left: auto; // align right
    }
    // all outlined but first
    .outlined ~ .outlined {
      margin-left: 0; // remove: align right
    }
    // social icons
    .social-icon {
      margin-right: 0;
      margin-top: -61px;
      .nav-link {
        height: 20px;
        width: 20px;
      }
      .fa {
        font-size: .6rem;
      }
    }
    // flex wrap social icons
    .social-icon {
      @include flex(0 0 25%);
      margin-left: auto; // align right
    }
    //  all social but first
    .social-icon ~ .social-icon {
      @include flex(0 0 5%);
      margin-left: 0; // remove: align right
    }
    // language switch
    .lang {
      top: 6px;
      a {
        font-size: 1.04rem;
      }
    }
    .lang::before {
      top: 6px;
    }
  }
  // nav bottom line
  .selector {
    position: relative;
  }
  // HP SLIDER
  #main-carousel {
    margin-top: 1rem;
    margin-bottom: 2rem;
    .slider-caption {
      width: 92%;
    }
    .caption {
      font-size: 1.5rem;
      margin-right: .5rem;
    }
    a.button {
      font-size: 1rem;
    }
    .owl-nav {
      .owl-prev,
      .owl-next {
        width: 20px;
        height: 20px;
      }
      .owl-prev::after {
        right: 36px;
      }
    }
  }
  // SECTIONS HP
  .section {
    margin-bottom: 2rem;
  }
  // section title
  .in-title {
    .title-h2 {
      font-size: 1.8rem;
    }
  }
  .news {
    .excerpt-i {
      padding: 1rem 1.5rem;
    }
    .wp-post-image {
      max-height: 115px;
      max-width: 150px;
    }
    .excerpt-h {
      font-size: 1.45rem;
      line-height: 1.25;
    }
  }
  // FOOTER
  footer {
    .rights {
      padding-top: 0;
    }
  }
}
@include media-breakpoint-up-xl {
  // NAVBAR
  .navbar-main {
    .navbar-brand {
      margin-right: 1rem;
      img {
        max-width: 85px;
      }
    }
    #navbarNav {
      .navbar-nav {
        .nav-item:not(.social-icon) {
          .nav-link {
            font-size: 1.3rem;
          }
        }
      }
    }
    // social icons
    .social-icon {
      margin-top: -68px;
      @include flex(0 0 43%); // flex wrap social icons
      .nav-link {
        height: 22px;
        width: 22px;
      }
    }
    //  all social but first
    .social-icon ~ .social-icon {
      @include flex(0 0 4.5%);
    }
    // language switch
    .lang {
      a {
        font-size: 1.1rem;
      }
    }
  }
  // HP SLIDER
  #main-carousel {
    margin-top: 1rem;
    margin-bottom: 3rem;
    .caption {
      font-size: 1.6rem;
    }
    a.button {
      font-size: 1rem;
      padding: .1rem 1.3rem .1rem .6rem;
      bottom: .1rem;
    }
    .raquo {
      position: absolute;
      top: -5px;
      right: 7px;
      font-size: 1.3rem;
    }
    .slider-caption {
      margin-top: .5rem;
    }
  }
  // HP SECTIONS
  a.button {
    padding: .7rem .9rem;
    font-size: 1rem;
  }
  .news {
    .wp-post-image {
      max-width: 180px;
    }
  }

  // FOOTER
  footer {
    // newsletter sign-up
    .newsletter-xl {
      height: 175px;
      .wrap {
        padding-left: 7rem;
        padding-right: 0;
      }
      h3 {
        font-size: 1.1rem;
        line-height: 2.1;
      }
      form {
        width: 50%;
        input[type=text] {
          height: 35px;
        }
        input[type=submit] {
          height: 35px;
        }
      }
    }
  }
}
