/* ----- DOWNLOADS ----- */

.pbsdownload {
  margin-top: 2rem;
  h2, p {
    color: $color-light;
    line-height: 1.5;
  }
  h2, p, a {
    font-size: 1.2rem;
  }
  h2 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    font-weight: 300;
  }
  .p-footnote {
    font-size: .9rem;
    margin-bottom: 0;
    margin-top: 2rem;
  }
  .p-footnote ~ .p-footnote {
    margin-top: 0;
  }
  p:not(.p-footnote) {
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  a {
    font-weight: 300;
    color: $color-red;
  }
  a:active,
  a:hover,
  a:focus {
    color: $color-light !important;
  }
  table {
    margin-bottom: .8rem;
    td {
      border: 1px solid $color-super-light;
      padding: 1rem;
    }
  }
  // Plugin MimeTypes Icons
  .mtli_attachment {
    padding-left: 30px !important;
    margin-left: 0;
    padding-top: 8px;
    line-height: 1.2;
  }
}
