// === Mediaqueries ABOUT SUB-PAGE == */

@include media-breakpoint-up-sm {
  .section-about {
    a.button {
      font-size: 1rem;
    }
  }
}

@include media-breakpoint-up-md {
  .in-wrap {
    padding-top: 0;
  }
  .section-about {
    .section {
      margin-bottom: 2rem;
    }
    a.button {
      display: inline-block;
      margin: .3rem 0 1.2rem 0;
      padding: .6rem 1.2rem;
    }
    .btn-style {
      border: 0;
      padding: 0;
      margin-bottom: 0;
      position: relative;
      img {
        position: absolute;
        top: 50%;
        right: 30px;
        @include transform(translateY(-50%));
      }
      .in-wrap {
        position: relative;
      }
    }
    // buttons in div wrap
    .btn-wrap {
      width: 100%;
      display: block;
      position: relative;
      height: 72px;
      a {
        float: right;
      }
    }
    .btn-wrap:last-of-type {
      height: 56px;
      a {
        margin-bottom: 0;
      }
    }
    .about-descript.section {
      margin-bottom: 1.5rem;
    }
    .about-icon {
      h3 {
        font-weight: 300;
        text-transform: uppercase;
        font-size: .8rem;
        text-align: center;
        line-height: 1.4;
        margin-top: .4rem;
      }
    }
    .about-icon:last-of-type {
      h3 {
        margin-bottom: 0;
      }
    }
    .icon-descript {
      text-transform: lowercase !important;
      font-size: .95rem !important;
      line-height: 1.2 !important;
    }
    .about-toggle {
      top: 0;
    }
    /* Cooperation and invitation*/
    .coop-item {
      img {
        margin-bottom: .5rem;
      }
    }
  }
}

@include media-breakpoint-up-lg {
  .section-about {
    margin-top: 0;
    a.button {
      font-size: 1.1rem;
    }
    .icon-img {
      max-width: 85%;
      margin: 0 auto;
    }
    .about-icon {
      h3 {
        font-size: 1rem;
        line-height: 1.3;
      }
    }
    .icon-descript {
      font-size: 1.1rem !important;
      line-height: 1.4 !important;
    }
    .distribution,
    .price,
    .logistics,
    .cat-online,
    .manage {
      .in-title {
        margin-bottom: .5rem;
      }
      .visual {
        margin: 0;
      }
    }
    .distribution,
    .price,
    .manage,
    .cat-online {
      .visual {
        width: 35%;
      }
      .descript {
        width: 65%;
      }
    }
    .distribution,
    .cat-online {
      .visual {
        float: left;
      }
      .descript {
      float: left;
      padding-left: 1.5rem;
      }
    }
    .price ,
    .manage {
      .visual {
        float: right;
      }
      .descript {
        float: left;
        padding-right: 1.5rem;
      }
    }
    .logistics {
      .visual {
        width: 50%;
        float: left;
      }
      .descript {
        width: 50%;
        float: left;
        padding-left: 1.5rem;
      }
    }
    /* Cooperation and invitation*/
    .coop-item {
      position: relative;
      img {
      display: inline-block;
      width: 120px;
      vertical-align: top;
      margin-right: 15px;
      margin-bottom: 0;
      padding: 0;
      }
      .coop-wrap {
        display: inline-block;
        width: 100%;
        text-align: left;
      }
      a.coop-l{
        position: absolute;
        bottom: 0;
        right: 0;
        margin-right: 30px;
        width: auto;
      }
    }
  }
}
@include media-breakpoint-up-xl {
  .section-about {
   /* Cooperation and invitation*/
    .coop-item {
      img {
      width: 115px;
      }
      .coop-wrap {
        width: 74%;
      }
    }
  }
}
