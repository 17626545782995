/* ----- FLIPPING CATALOGUE ----- */

.catalogue {
  width: 100%;
  height: 95vh;
  position: relative;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  iframe {
    width: 100%;
    height: 100%;
    position: relative;
    top: auto;
    left: 0px;
    border: 0;
  }
}
