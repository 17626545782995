// ----- Bootstrap breakpoints -----
$breakpoint-max-xs: 575px;
$breakpoint-min-sm: 576px;
$breakpoint-max-sm: 767px;
$breakpoint-min-md: 768px;
$breakpoint-max-md: 991px;
$breakpoint-min-lg: 992px;
$breakpoint-max-lg: 1199px;
$breakpoint-min-xl: 1200px;

// ----- Extra breakpoints -----
$breakpoint-min-360: 360px;
$breakpoint-min-480: 480px;

// ----- project colors -----
$color-basic: #4d4d4d;
$color-light: #999999;
$color-super-light: #cccccc;
$color-red: #e1001a;

$color-bg-basic: #4d4d4d;
$color-bg: #efefef;
$color-bg-box: #e1001a;
$color-bg-2: #dfdfdf;

// NEW CLIENTS background colors
$color-bg-cl-a: #e1001a;
$color-bg-cl-b: #f2d50e;
$color-bg-cl-c: #1c9fe3;
$color-bg-cl-d: #15cc33;
